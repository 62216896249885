.Main{
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;
}
@media screen and (min-width: 720px){
  .Main{
    max-width: 720px;
  }
}
@media screen and (min-width: 1024px){
  .Main{
    max-width: 900px;
  }
}