.Header{
  height: var(--header-height);
  background-color: var(--dark);
  &-content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    height: 100%;
    color: var(--blank);
  }
}