.Total{
  margin-top: 30px;
  h2{
    text-align: center;
  }
  &>p{
    margin: 10px 0;
    display: grid;
    border-radius: 5px;
    padding: 20px 30px;
    grid-template-columns: repeat(2, auto);
    grid-auto-rows: auto;
    grid-gap: 15px;
    align-items: center;
    justify-content: start;
    font-size: 16px;
    grid-template-areas: 
      "s p";
    span{
      font-size: 2em;
      color: var(--blank);
      grid-area: s;
      font-weight: 700;
    }
    p{
      grid-area: p;
      font-size: 1em;
      font-weight: 300;
      color: var(--blank);
    }
  }
  &-Tables{
    background-color: gray;
  }
  &-Users{
    background-color: rgba(255, 99, 132, 1);
  }
  &-Documents{
    background-color: rgba(54, 162, 235, 1);
  }
  &-News{
    background-color: rgba(255, 206, 86, 1);
  }
}