.AddDocument{
  a{
    color: var(--dark);
  }
  .InputGroup{
    height: 50px;
    display: flex;
    border-radius: 25px;
    padding: 10px 20px;
    border: 2px solid var(--dark);
    margin-bottom: 20px;
    input{
      flex: 1;
      border: 0;
      border-radius: 50px 0 0 50px;
      outline: none;
      font-size: 14px;
      display: block;
      padding-left: 2px;
      padding-right: 20px;
    }
    label{
      flex: 1;
      padding: 5px 10px;
      margin-right: 20px;
    }
    i{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .InputGroup-body{
    height: auto;
    border-radius: 0;
    padding: 0px;
  }
  .input-file-container {
    position: relative;
    height: auto;
    margin-bottom: 0px;
  }
  button[type="submit"]{
    margin: 0 auto;
    display: block;
    padding: 15px 50px;
    background-color: var(--dark);
    color: var(--blank);
    border: 0;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
  }
  &-Log{
    font-family: 'Courier New', Courier, monospace;
    padding: 10px 10px;
    height: 500px;
    margin-top: 20px;
    border: 2px solid var(--dark);
    border-radius: 8px;
    overflow: auto;
    p{
      font-family: inherit;
      margin: 0;
    }
    p.error{
      color: red;
    }
    p.success{
      color: green;
    }
  }
}

@media screen and (min-width: 720px){
  .AddDocument{
    .Section-content{
      display: grid;
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr;
    }
    &-Log{
      height: 100%;
      border-radius: 0;
      margin-top: 0;
    }
  }
}

.EditDocument{
  a{
    color: var(--dark);
  }
  .InputGroup{
    height: 50px;
    display: flex;
    border-radius: 25px;
    padding: 10px 20px;
    border: 2px solid var(--dark);
    margin-bottom: 20px;
    input{
      flex: 1;
      border: 0;
      border-radius: 50px 0 0 50px;
      outline: none;
      font-size: 14px;
      display: block;
      padding-left: 2px;
      padding-right: 20px;
    }
    label{
      flex: 1;
      padding: 5px 10px;
      margin-right: 20px;
    }
    i{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .InputGroup.disabled{
    background-color: rgb(212, 212, 212);
    cursor: not-allowed;
    input[disabled]{
      cursor: not-allowed;
      background-color: rgb(212, 212, 212);
    }
  }
  &-Result{
    p{
      margin-bottom: 10px;
      margin-left: 20px;
    }
    p:last-child{
      margin-bottom: 20px;
    }
  }
  .InputGroup-body{
    height: auto;
    border-radius: 0;
    padding: 0px;
  }
  .input-file-container {
    position: relative;
    height: auto;
    margin-bottom: 0px;
  }
  button[type="submit"]{
    margin: 0 auto;
    display: block;
    padding: 15px 50px;
    background-color: var(--dark);
    color: var(--blank);
    border: 0;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
  }
  &-Log{
    font-family: 'Courier New', Courier, monospace;
    padding: 10px 10px;
    height: 500px;
    margin-top: 20px;
    border: 2px solid var(--dark);
    border-radius: 8px;
    overflow: auto;
    p{
      font-family: inherit;
      margin: 0;
    }
    p.error{
      color: red;
    }
    p.success{
      color: green;
    }
  }
}

@media screen and (min-width: 720px){
  .EditDocument{
    .Section-content{
      display: grid;
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr;
    }
    &-Log{
      height: 100%;
      border-radius: 0;
      margin-top: 0;
    }
  }
}

.DeleteDocument{
  a{
    color: var(--dark);
  }
  .InputGroup{
    height: 50px;
    display: flex;
    border-radius: 25px;
    padding: 10px 20px;
    border: 2px solid var(--dark);
    margin-bottom: 20px;
    input{
      flex: 1;
      border: 0;
      border-radius: 50px 0 0 50px;
      outline: none;
      font-size: 14px;
      display: block;
      padding-left: 2px;
      padding-right: 20px;
    }
    label{
      flex: 1;
      padding: 5px 10px;
      margin-right: 20px;
    }
    i{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .InputGroup.disabled{
    background-color: rgb(212, 212, 212);
    cursor: not-allowed;
    input[disabled]{
      cursor: not-allowed;
      background-color: rgb(212, 212, 212);
    }
  }
  &-Result{
    p{
      margin-bottom: 10px;
      margin-left: 20px;
    }
    p:last-child{
      margin-bottom: 20px;
    }
  }
  .InputGroup-body{
    height: auto;
    border-radius: 0;
    padding: 0px;
  }
  .input-file-container {
    position: relative;
    height: auto;
    margin-bottom: 0px;
  }
  button[type="submit"]{
    margin: 0 auto;
    display: block;
    padding: 15px 50px;
    background-color: var(--dark);
    color: var(--blank);
    border: 0;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
  }
  &-Log{
    font-family: 'Courier New', Courier, monospace;
    padding: 10px 10px;
    height: 500px;
    margin-top: 20px;
    border: 2px solid var(--dark);
    border-radius: 8px;
    overflow: auto;
    p{
      font-family: inherit;
      margin: 0;
    }
    p.error{
      color: red;
    }
    p.success{
      color: green;
    }
  }
}

@media screen and (min-width: 720px){
  .DeleteDocument{
    .Section-content{
      display: grid;
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr;
    }
    &-Log{
      height: 100%;
      border-radius: 0;
      margin-top: 0;
    }
  }
}