.Panel-menu{
  font-weight: 300;
  display: inline-block;
  color: var(--blank);
  padding: 10px 0;
  height: 100vh;
  width: 250px;
  text-align: left;
  padding-top: 10px;
  border-bottom: 1px solid var(--dark);
  overflow: auto;
  &>p{
    padding: 10px;
  }
  &>ul{
    &>li{
      padding: 10px 20px;
      display: flex;
      align-items: center;
      font-weight: 300;
      cursor: pointer;
      p{
        margin-left: 15px;
        margin-right: 15px;
        flex: 1;
      }
      i{
        transition: transform var(--time-transition);
      }
      i.is-active{
        transform: rotate(-180deg);
      }
    }
    &>li:hover{
      background-color: var(--dark-hover);
    }
    &>li.is-active{
      background-color: var(--dark-hover);
    }
    ul{
      padding-left: 50px;
      background-color: var(--dark-hover);
      overflow: hidden;
      transition: height var(--time-transition);
      li{
        cursor: pointer;
        list-style-type: disc;
        padding: 10px 20px 10px 0;
      }
    }
  }
  form{
    button{
      padding: 10px 20px;
      background-color: var(--middle);
      margin: 10px 20px;
      color: var(--blank);
      border: 2px solid var(--middle);
      border-radius: 20px;
      transition: background-color .25s;
      &:hover{
        background-color: var(--dark-hover);
        border: 2px solid var(--middle);
        transition: background-color .25s;
      }
    }
  }
}