.Users{
  a{
    color: var(--dark);
  }
  canvas{
    width: 100%;
  }
  .Tables{
    display: grid;
    grid-gap: 20px;
    align-items: start;
  }
  .LastUsers{
    padding: 20px;
    background-color: var(--dark);
    h3{
      margin-bottom: 20px;
      color: var(--blank);
    }
  }
  .LastUsers-item{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    align-items: center;
    justify-content: start;
    grid-gap: 5px 15px;
    padding: 10px 30px;
    color: var(--blank);
    background-color: var(--dark);
    border-bottom: 1px solid var(--blank);
    grid-template-areas: 
    "id name"
    "id email";
    &:first-of-type{
      padding-top: 0;
    }
    &:last-child{
      border: 0;
      padding-bottom: 0;
    }
    p{
      color: var(--blank);
    }
    p:nth-child(1){
      grid-area: id;
      font-weight: 700;
    }
    p:nth-child(2){
      grid-area: name;
      font-weight: 700;
    }
    p:nth-child(3){
      grid-area: email;
      font-style: italic;
    }
  }
}
@media screen and (min-width: 720px){
  .Users{
    canvas{
      max-width: 400px;
    }
    .Tables{
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.AddUser{
  .InputGroup{
    height: 50px;
    display: flex;
    border-radius: 25px;
    padding: 10px 20px;
    border: 2px solid var(--dark);
    margin-bottom: 20px;
    input{
      flex: 1;
      border: 0;
      border-radius: 50px 0 0 50px;
      outline: none;
      font-size: 14px;
      display: block;
      padding-left: 2px;
    }
    i{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  button[type="submit"]{
    margin: 0 auto;
    display: block;
    padding: 15px 50px;
    background-color: var(--dark);
    color: var(--blank);
    border: 0;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
  }
  &-Log{
    font-family: 'Courier New', Courier, monospace;
    padding: 10px 10px;
    height: 300px;
    margin-top: 20px;
    border: 2px solid var(--dark);
    border-radius: 8px;
    overflow: auto;
    p{
      font-family: inherit;
      margin: 0;
    }
    p.error{
      color: red;
    }
    p.success{
      color: green;
    }
  }
}

@media screen and (min-width: 720px){
  .AddUser{
    .Section-content{
      display: grid;
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr;
    }
    &-Log{
      height: 400px;
      border-radius: 0;
      margin-top: 0;
    }
  }
}

.EditUser{
  .InputGroup{
    height: 50px;
    display: flex;
    border-radius: 25px;
    padding: 10px 20px;
    border: 2px solid var(--dark);
    margin-bottom: 20px;
    input{
      flex: 1;
      border: 0;
      border-radius: 50px 0 0 50px;
      outline: none;
      font-size: 14px;
      display: block;
      padding-left: 2px;
    }
    i{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &-Result{
    p{
      margin-bottom: 10px;
      margin-left: 20px;
    }
    p:last-child{
      margin-bottom: 20px;
    }
  }
  button[type="submit"]{
    margin: 0 auto;
    display: block;
    padding: 15px 50px;
    background-color: var(--dark);
    color: var(--blank);
    border: 0;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
  }
  &-Log{
    font-family: 'Courier New', Courier, monospace;
    padding: 10px 10px;
    height: 300px;
    margin-top: 20px;
    border: 2px solid var(--dark);
    border-radius: 8px;
    overflow: auto;
    p{
      font-family: inherit;
      margin: 0;
    }
    p.error{
      color: red;
    }
    p.success{
      color: green;
    }
  }
}

@media screen and (min-width: 720px){
  .EditUser{
    .Section-content{
      display: grid;
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr;
    }
    &-Log{
      height: 600px;
      border-radius: 0;
      margin-top: 0;
    }
  }
}

.DeleteUser{
  .InputGroup{
    height: 50px;
    display: flex;
    border-radius: 25px;
    padding: 10px 20px;
    border: 2px solid var(--dark);
    margin-bottom: 20px;
    input{
      flex: 1;
      border: 0;
      border-radius: 50px 0 0 50px;
      outline: none;
      font-size: 14px;
      display: block;
      padding-left: 2px;
    }
    i{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .InputGroup.disabled{
    background-color: rgb(212, 212, 212);
    cursor: not-allowed;
    input[disabled]{
      cursor: not-allowed;
      background-color: rgb(212, 212, 212);

    }
  }
  &-Result{
    p{
      margin-bottom: 10px;
      margin-left: 20px;
    }
    p:last-child{
      margin-bottom: 20px;
    }
  }
  button[type="submit"]{
    margin: 0 auto;
    display: block;
    padding: 15px 50px;
    background-color: var(--dark);
    color: var(--blank);
    border: 0;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
  }
  &-Log{
    font-family: 'Courier New', Courier, monospace;
    padding: 10px 10px;
    height: 300px;
    margin-top: 20px;
    border: 2px solid var(--dark);
    border-radius: 8px;
    overflow: auto;
    p{
      font-family: inherit;
      margin: 0;
    }
    p.error{
      color: red;
    }
    p.success{
      color: green;
    }
  }
}

@media screen and (min-width: 720px){
  .DeleteUser{
    .Section-content{
      display: grid;
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr;
    }
    &-Log{
      height: 400px;
      border-radius: 0;
      margin-top: 0;
    }
  }
}