:root{
  //FUENTES
  --main-font: 'Roboto';
  --alternate-font: sans-serif;
  //COLORES
  --dark: #2d3e4e;
  --middle: #306392;
  --dark-hover: #2c3949;
  --blank: #fff;
  --dark-modal: rgba(45, 62, 78, .5);
  //TIEMPOS
  --time-transition: .5s;
  //TAMAÑOS
  --header-height: 64px;
}