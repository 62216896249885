*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--main-font), var(--alternate-font);
  font-size: 14px;
}
*,
*:after,
*:before{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body{
  overflow-x: hidden;
  background-color: var(--blank);
  transition: transform var(--time-transition);
}
body.disabled{
  transition: transform var(--time-transition);
  overflow: hidden;
  transform: translate(250px);
}

a{
  text-decoration: none;
  color: white;
  transition: all .3s ease-out;
  display: block;
}

img{
  display: block;
  max-width: 100%;
  height: auto;
}

li{
  list-style-position: inside;
  list-style: none;
}

::selection{
  background-color: var(--dark);
  color: white;
}

h1{
  font-family: var(--main-font), var(--alternate-font);
  font-size: 40px;
  line-height: 48px;
  font-weight: 900;
  text-transform: uppercase;
}
h2{
  font-family: var(--main-font), var(--alternate-font);
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  text-transform: uppercase;
}
h3{
  font-family: var(--main-font), var(--alternate-font);
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  text-transform: uppercase;
}
::-webkit-scrollbar {
  width: 0.5rem;
}
/* Track */
::-webkit-scrollbar-track {
  background: #ffffff; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--middle);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--dark-hover);
}