:root {
  --main-font: 'Roboto';
  --alternate-font: sans-serif;
  --dark: #2d3e4e;
  --middle: #306392;
  --dark-hover: #2c3949;
  --blank: #fff;
  --dark-modal: rgba(45, 62, 78, .5);
  --time-transition: .5s;
  --header-height: 64px; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--main-font), var(--alternate-font);
  font-size: 14px; }

*,
*:after,
*:before {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

body {
  overflow-x: hidden;
  background-color: var(--blank);
  transition: transform var(--time-transition); }

body.disabled {
  transition: transform var(--time-transition);
  overflow: hidden;
  transform: translate(250px); }

a {
  text-decoration: none;
  color: white;
  transition: all .3s ease-out;
  display: block; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

li {
  list-style-position: inside;
  list-style: none; }

::selection {
  background-color: var(--dark);
  color: white; }

h1 {
  font-family: var(--main-font), var(--alternate-font);
  font-size: 40px;
  line-height: 48px;
  font-weight: 900;
  text-transform: uppercase; }

h2 {
  font-family: var(--main-font), var(--alternate-font);
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  text-transform: uppercase; }

h3 {
  font-family: var(--main-font), var(--alternate-font);
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  text-transform: uppercase; }

::-webkit-scrollbar {
  width: 0.5rem; }

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--middle); }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--dark-hover); }

.Header {
  height: var(--header-height);
  background-color: var(--dark); }
  .Header-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    height: 100%;
    color: var(--blank); }

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-box-shadow: 0 0 0px 1000px var(--blank) inset;
  transition: background-color 5000s ease-in-out 0s; }

.Logo {
  display: flex;
  align-items: center;
  color: var(--blank);
  height: 100%; }
  .Logo img {
    height: 100%; }

.Panel-menu {
  font-weight: 300;
  display: inline-block;
  color: var(--blank);
  padding: 10px 0;
  height: 100vh;
  width: 250px;
  text-align: left;
  padding-top: 10px;
  border-bottom: 1px solid var(--dark);
  overflow: auto; }
  .Panel-menu > p {
    padding: 10px; }
  .Panel-menu > ul > li {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-weight: 300;
    cursor: pointer; }
    .Panel-menu > ul > li p {
      margin-left: 15px;
      margin-right: 15px;
      flex: 1; }
    .Panel-menu > ul > li i {
      transition: transform var(--time-transition); }
    .Panel-menu > ul > li i.is-active {
      transform: rotate(-180deg); }
  .Panel-menu > ul > li:hover {
    background-color: var(--dark-hover); }
  .Panel-menu > ul > li.is-active {
    background-color: var(--dark-hover); }
  .Panel-menu > ul ul {
    padding-left: 50px;
    background-color: var(--dark-hover);
    overflow: hidden;
    transition: height var(--time-transition); }
    .Panel-menu > ul ul li {
      cursor: pointer;
      list-style-type: disc;
      padding: 10px 20px 10px 0; }
  .Panel-menu form button {
    padding: 10px 20px;
    background-color: var(--middle);
    margin: 10px 20px;
    color: var(--blank);
    border: 2px solid var(--middle);
    border-radius: 20px;
    transition: background-color .25s; }
    .Panel-menu form button:hover {
      background-color: var(--dark-hover);
      border: 2px solid var(--middle);
      transition: background-color .25s; }

.Forms > * {
  margin-bottom: 20px; }

.Forms > *:last-child {
  margin-bottom: 0; }

.Panel {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  transform: translate(-100%);
  transition: transform var(--time-transition);
  text-align: left;
  background-color: var(--dark); }
  .Panel-btn {
    width: 100%;
    padding: 10px;
    margin-right: 15px;
    border: 1px solid var(--blank);
    outline: none;
    background-color: transparent;
    color: var(--blank);
    border-radius: 2px;
    flex: 0;
    z-index: 10; }
  .Panel.is-active {
    transform: translate(-100%);
    transition: transform var(--time-transition); }

.Main {
  padding: 20px;
  max-width: 400px;
  margin: 0 auto; }

@media screen and (min-width: 720px) {
  .Main {
    max-width: 720px; } }

@media screen and (min-width: 1024px) {
  .Main {
    max-width: 900px; } }

.Login {
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 999;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform .25s .75s, background-color .75s;
  background-color: var(--dark); }
  .Login-body {
    background-color: white;
    padding: 1rem;
    width: 90%;
    max-width: 360px;
    border-radius: .2rem; }
  .Login h3 {
    text-align: center;
    padding-bottom: 1rem;
    border-bottom: 2px solid var(--dark);
    font-weight: 400; }
  .Login form {
    margin-top: 2rem;
    text-align: center; }
    .Login form input {
      border: 0;
      background-color: #eee;
      width: 100%;
      padding: 0.9rem;
      outline: none;
      margin-bottom: 1.14rem;
      border-radius: .2rem;
      font-family: var(--main-font), var(--alternate-font); }
    .Login form button {
      padding: 0.75em 0.9em;
      border: 2px solid var(--dark);
      outline: none;
      cursor: pointer;
      background-color: transparent; }
    .Login form p {
      color: red;
      text-align: left; }
  .Login .Info {
    margin-top: 2rem;
    text-align: center; }
    .Login .Info h6 {
      font-weight: 700; }
    .Login .Info a {
      text-decoration: underline;
      color: var(--dark); }
  .Login .Login-footer {
    margin-top: 2rem;
    text-align: center; }
    .Login .Login-footer h5 {
      font-weight: 700; }
    .Login .Login-footer a {
      display: inline;
      text-decoration: underline;
      color: var(--dark); }
  .Login p {
    color: white;
    margin-top: 5px; }

.Section {
  display: none; }
  .Section-title {
    margin-bottom: 20px;
    text-align: center;
    color: var(--dark); }
  .Section.is-active {
    display: block; }

.Total {
  margin-top: 30px; }
  .Total h2 {
    text-align: center; }
  .Total > p {
    margin: 10px 0;
    display: grid;
    border-radius: 5px;
    padding: 20px 30px;
    grid-template-columns: repeat(2, auto);
    grid-auto-rows: auto;
    grid-gap: 15px;
    align-items: center;
    justify-content: start;
    font-size: 16px;
    grid-template-areas: "s p"; }
    .Total > p span {
      font-size: 2em;
      color: var(--blank);
      grid-area: s;
      font-weight: 700; }
    .Total > p p {
      grid-area: p;
      font-size: 1em;
      font-weight: 300;
      color: var(--blank); }
  .Total-Tables {
    background-color: gray; }
  .Total-Users {
    background-color: #ff6384; }
  .Total-Documents {
    background-color: #36a2eb; }
  .Total-News {
    background-color: #ffce56; }

canvas {
  margin: 0 auto; }

@media screen and (min-width: 720px) {
  .Tables {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px; }
    .Tables canvas {
      max-width: 400px;
      margin: 0 auto; }
    .Tables .Total {
      margin-top: 0;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr; }
      .Tables .Total > p {
        margin: 0;
        border-radius: 2px; } }

@media screen and (min-width: 770px) {
  .Tables canvas {
    max-width: 400px;
    margin: 0 auto; } }

@media screen and (min-width: 1024px) {
  .Tables {
    align-content: center;
    align-items: center; }
    .Tables canvas {
      max-width: 500px; }
    .Tables .Total {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(1, 1fr);
      align-content: start;
      align-items: start; }
      .Tables .Total > p {
        margin: 0;
        border-radius: 5px;
        height: auto;
        padding: 30px; } }

.Users a {
  color: var(--dark); }

.Users canvas {
  width: 100%; }

.Users .Tables {
  display: grid;
  grid-gap: 20px;
  align-items: start; }

.Users .LastUsers {
  padding: 20px;
  background-color: var(--dark); }
  .Users .LastUsers h3 {
    margin-bottom: 20px;
    color: var(--blank); }

.Users .LastUsers-item {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  align-items: center;
  justify-content: start;
  grid-gap: 5px 15px;
  padding: 10px 30px;
  color: var(--blank);
  background-color: var(--dark);
  border-bottom: 1px solid var(--blank);
  grid-template-areas: "id name" "id email"; }
  .Users .LastUsers-item:first-of-type {
    padding-top: 0; }
  .Users .LastUsers-item:last-child {
    border: 0;
    padding-bottom: 0; }
  .Users .LastUsers-item p {
    color: var(--blank); }
  .Users .LastUsers-item p:nth-child(1) {
    grid-area: id;
    font-weight: 700; }
  .Users .LastUsers-item p:nth-child(2) {
    grid-area: name;
    font-weight: 700; }
  .Users .LastUsers-item p:nth-child(3) {
    grid-area: email;
    font-style: italic; }

@media screen and (min-width: 720px) {
  .Users canvas {
    max-width: 400px; }
  .Users .Tables {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr; } }

.AddUser .InputGroup {
  height: 50px;
  display: flex;
  border-radius: 25px;
  padding: 10px 20px;
  border: 2px solid var(--dark);
  margin-bottom: 20px; }
  .AddUser .InputGroup input {
    flex: 1;
    border: 0;
    border-radius: 50px 0 0 50px;
    outline: none;
    font-size: 14px;
    display: block;
    padding-left: 2px; }
  .AddUser .InputGroup i {
    display: flex;
    justify-content: center;
    align-items: center; }

.AddUser button[type="submit"] {
  margin: 0 auto;
  display: block;
  padding: 15px 50px;
  background-color: var(--dark);
  color: var(--blank);
  border: 0;
  border-radius: 25px;
  outline: none;
  cursor: pointer; }

.AddUser-Log {
  font-family: 'Courier New', Courier, monospace;
  padding: 10px 10px;
  height: 300px;
  margin-top: 20px;
  border: 2px solid var(--dark);
  border-radius: 8px;
  overflow: auto; }
  .AddUser-Log p {
    font-family: inherit;
    margin: 0; }
  .AddUser-Log p.error {
    color: red; }
  .AddUser-Log p.success {
    color: green; }

@media screen and (min-width: 720px) {
  .AddUser .Section-content {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr; }
  .AddUser-Log {
    height: 400px;
    border-radius: 0;
    margin-top: 0; } }

.EditUser .InputGroup {
  height: 50px;
  display: flex;
  border-radius: 25px;
  padding: 10px 20px;
  border: 2px solid var(--dark);
  margin-bottom: 20px; }
  .EditUser .InputGroup input {
    flex: 1;
    border: 0;
    border-radius: 50px 0 0 50px;
    outline: none;
    font-size: 14px;
    display: block;
    padding-left: 2px; }
  .EditUser .InputGroup i {
    display: flex;
    justify-content: center;
    align-items: center; }

.EditUser-Result p {
  margin-bottom: 10px;
  margin-left: 20px; }

.EditUser-Result p:last-child {
  margin-bottom: 20px; }

.EditUser button[type="submit"] {
  margin: 0 auto;
  display: block;
  padding: 15px 50px;
  background-color: var(--dark);
  color: var(--blank);
  border: 0;
  border-radius: 25px;
  outline: none;
  cursor: pointer; }

.EditUser-Log {
  font-family: 'Courier New', Courier, monospace;
  padding: 10px 10px;
  height: 300px;
  margin-top: 20px;
  border: 2px solid var(--dark);
  border-radius: 8px;
  overflow: auto; }
  .EditUser-Log p {
    font-family: inherit;
    margin: 0; }
  .EditUser-Log p.error {
    color: red; }
  .EditUser-Log p.success {
    color: green; }

@media screen and (min-width: 720px) {
  .EditUser .Section-content {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr; }
  .EditUser-Log {
    height: 600px;
    border-radius: 0;
    margin-top: 0; } }

.DeleteUser .InputGroup {
  height: 50px;
  display: flex;
  border-radius: 25px;
  padding: 10px 20px;
  border: 2px solid var(--dark);
  margin-bottom: 20px; }
  .DeleteUser .InputGroup input {
    flex: 1;
    border: 0;
    border-radius: 50px 0 0 50px;
    outline: none;
    font-size: 14px;
    display: block;
    padding-left: 2px; }
  .DeleteUser .InputGroup i {
    display: flex;
    justify-content: center;
    align-items: center; }

.DeleteUser .InputGroup.disabled {
  background-color: #d4d4d4;
  cursor: not-allowed; }
  .DeleteUser .InputGroup.disabled input[disabled] {
    cursor: not-allowed;
    background-color: #d4d4d4; }

.DeleteUser-Result p {
  margin-bottom: 10px;
  margin-left: 20px; }

.DeleteUser-Result p:last-child {
  margin-bottom: 20px; }

.DeleteUser button[type="submit"] {
  margin: 0 auto;
  display: block;
  padding: 15px 50px;
  background-color: var(--dark);
  color: var(--blank);
  border: 0;
  border-radius: 25px;
  outline: none;
  cursor: pointer; }

.DeleteUser-Log {
  font-family: 'Courier New', Courier, monospace;
  padding: 10px 10px;
  height: 300px;
  margin-top: 20px;
  border: 2px solid var(--dark);
  border-radius: 8px;
  overflow: auto; }
  .DeleteUser-Log p {
    font-family: inherit;
    margin: 0; }
  .DeleteUser-Log p.error {
    color: red; }
  .DeleteUser-Log p.success {
    color: green; }

@media screen and (min-width: 720px) {
  .DeleteUser .Section-content {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr; }
  .DeleteUser-Log {
    height: 400px;
    border-radius: 0;
    margin-top: 0; } }

.AddNews a {
  color: var(--dark); }

.AddNews .InputGroup {
  height: 50px;
  display: flex;
  border-radius: 25px;
  padding: 10px 20px;
  border: 2px solid var(--dark);
  margin-bottom: 20px; }
  .AddNews .InputGroup input {
    flex: 1;
    border: 0;
    border-radius: 50px 0 0 50px;
    outline: none;
    font-size: 14px;
    display: block;
    padding-left: 2px;
    padding-right: 20px; }
  .AddNews .InputGroup label {
    flex: 1;
    padding: 5px 10px;
    margin-right: 20px; }
  .AddNews .InputGroup i {
    display: flex;
    justify-content: center;
    align-items: center; }

.AddNews .InputGroup-body {
  height: auto;
  border-radius: 0;
  padding: 0px; }

.AddNews .input-file-container {
  position: relative;
  height: auto;
  margin-bottom: 0px; }

.AddNews button[type="submit"] {
  margin: 0 auto;
  display: block;
  padding: 15px 50px;
  background-color: var(--dark);
  color: var(--blank);
  border: 0;
  border-radius: 25px;
  outline: none;
  cursor: pointer; }

.AddNews-Log {
  font-family: 'Courier New', Courier, monospace;
  padding: 10px 10px;
  height: 500px;
  margin-top: 20px;
  border: 2px solid var(--dark);
  border-radius: 8px;
  overflow: auto; }
  .AddNews-Log p {
    font-family: inherit;
    margin: 0; }
  .AddNews-Log p.error {
    color: red; }
  .AddNews-Log p.success {
    color: green; }

@media screen and (min-width: 720px) {
  .AddNews .Section-content {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr; }
  .AddNews-Log {
    height: 100%;
    border-radius: 0;
    margin-top: 0; } }

.EditNews a {
  color: var(--dark); }

.EditNews .InputGroup {
  height: 50px;
  display: flex;
  border-radius: 25px;
  padding: 10px 20px;
  border: 2px solid var(--dark);
  margin-bottom: 20px; }
  .EditNews .InputGroup input {
    flex: 1;
    border: 0;
    border-radius: 50px 0 0 50px;
    outline: none;
    font-size: 14px;
    display: block;
    padding-left: 2px;
    padding-right: 20px; }
  .EditNews .InputGroup label {
    flex: 1;
    padding: 5px 10px;
    margin-right: 20px; }
  .EditNews .InputGroup i {
    display: flex;
    justify-content: center;
    align-items: center; }

.EditNews-Result p {
  margin-bottom: 10px;
  margin-left: 20px; }

.EditNews-Result p:last-child {
  margin-bottom: 20px; }

.EditNews .InputGroup-body {
  height: auto;
  border-radius: 0;
  padding: 0px; }

.EditNews .input-file-container {
  position: relative;
  height: auto;
  margin-bottom: 0px; }

.EditNews button[type="submit"] {
  margin: 0 auto;
  display: block;
  padding: 15px 50px;
  background-color: var(--dark);
  color: var(--blank);
  border: 0;
  border-radius: 25px;
  outline: none;
  cursor: pointer; }

.EditNews-Log {
  font-family: 'Courier New', Courier, monospace;
  padding: 10px 10px;
  height: 500px;
  margin-top: 20px;
  border: 2px solid var(--dark);
  border-radius: 8px;
  overflow: auto; }
  .EditNews-Log p {
    font-family: inherit;
    margin: 0; }
  .EditNews-Log p.error {
    color: red; }
  .EditNews-Log p.success {
    color: green; }

@media screen and (min-width: 720px) {
  .EditNews .Section-content {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr; }
  .EditNews-Log {
    height: 100%;
    border-radius: 0;
    margin-top: 0; } }

.DeleteNews a {
  color: var(--dark); }

.DeleteNews .InputGroup {
  height: 50px;
  display: flex;
  border-radius: 25px;
  padding: 10px 20px;
  border: 2px solid var(--dark);
  margin-bottom: 20px; }
  .DeleteNews .InputGroup input {
    flex: 1;
    border: 0;
    border-radius: 50px 0 0 50px;
    outline: none;
    font-size: 14px;
    display: block;
    padding-left: 2px;
    padding-right: 20px; }
  .DeleteNews .InputGroup label {
    flex: 1;
    padding: 5px 10px;
    margin-right: 20px; }
  .DeleteNews .InputGroup i {
    display: flex;
    justify-content: center;
    align-items: center; }

.DeleteNews-Result p {
  margin-bottom: 10px;
  margin-left: 20px; }

.DeleteNews-Result p:last-child {
  margin-bottom: 20px; }

.DeleteNews .InputGroup-body {
  height: auto;
  border-radius: 0;
  padding: 0px; }

.DeleteNews .input-file-container {
  position: relative;
  height: auto;
  margin-bottom: 0px; }

.DeleteNews button[type="submit"] {
  margin: 0 auto;
  display: block;
  padding: 15px 50px;
  background-color: var(--dark);
  color: var(--blank);
  border: 0;
  border-radius: 25px;
  outline: none;
  cursor: pointer; }

.DeleteNews-Log {
  font-family: 'Courier New', Courier, monospace;
  padding: 10px 10px;
  height: 500px;
  margin-top: 20px;
  border: 2px solid var(--dark);
  border-radius: 8px;
  overflow: auto; }
  .DeleteNews-Log p {
    font-family: inherit;
    margin: 0; }
  .DeleteNews-Log p.error {
    color: red; }
  .DeleteNews-Log p.success {
    color: green; }

@media screen and (min-width: 720px) {
  .DeleteNews .Section-content {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr; }
  .DeleteNews-Log {
    height: 100%;
    border-radius: 0;
    margin-top: 0; } }

.input-file-container {
  /* position: relative;
  height: 100px; */ }

.js .input-file-trigger {
  display: block;
  padding: 5px 45px;
  background: var(--dark);
  color: #fff;
  font-size: 1em;
  transition: all .4s;
  cursor: pointer; }

.js .input-file {
  position: absolute;
  top: 0;
  left: 0;
  width: 225px;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer; }

.js .input-file:hover + .input-file-trigger,
.js .input-file:focus + .input-file-trigger,
.js .input-file-trigger:hover,
.js .input-file-trigger:focus {
  background: #34495E;
  color: #39D2B4; }

.file-return {
  padding: 10px 20px;
  margin: 0; }

.js .file-return {
  font-style: italic;
  font-size: .9em;
  font-weight: bold; }

.js .file-return:not(:empty):before {
  content: "Selected file: ";
  font-style: normal;
  font-weight: normal; }

.AddDocument a {
  color: var(--dark); }

.AddDocument .InputGroup {
  height: 50px;
  display: flex;
  border-radius: 25px;
  padding: 10px 20px;
  border: 2px solid var(--dark);
  margin-bottom: 20px; }
  .AddDocument .InputGroup input {
    flex: 1;
    border: 0;
    border-radius: 50px 0 0 50px;
    outline: none;
    font-size: 14px;
    display: block;
    padding-left: 2px;
    padding-right: 20px; }
  .AddDocument .InputGroup label {
    flex: 1;
    padding: 5px 10px;
    margin-right: 20px; }
  .AddDocument .InputGroup i {
    display: flex;
    justify-content: center;
    align-items: center; }

.AddDocument .InputGroup-body {
  height: auto;
  border-radius: 0;
  padding: 0px; }

.AddDocument .input-file-container {
  position: relative;
  height: auto;
  margin-bottom: 0px; }

.AddDocument button[type="submit"] {
  margin: 0 auto;
  display: block;
  padding: 15px 50px;
  background-color: var(--dark);
  color: var(--blank);
  border: 0;
  border-radius: 25px;
  outline: none;
  cursor: pointer; }

.AddDocument-Log {
  font-family: 'Courier New', Courier, monospace;
  padding: 10px 10px;
  height: 500px;
  margin-top: 20px;
  border: 2px solid var(--dark);
  border-radius: 8px;
  overflow: auto; }
  .AddDocument-Log p {
    font-family: inherit;
    margin: 0; }
  .AddDocument-Log p.error {
    color: red; }
  .AddDocument-Log p.success {
    color: green; }

@media screen and (min-width: 720px) {
  .AddDocument .Section-content {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr; }
  .AddDocument-Log {
    height: 100%;
    border-radius: 0;
    margin-top: 0; } }

.EditDocument a {
  color: var(--dark); }

.EditDocument .InputGroup {
  height: 50px;
  display: flex;
  border-radius: 25px;
  padding: 10px 20px;
  border: 2px solid var(--dark);
  margin-bottom: 20px; }
  .EditDocument .InputGroup input {
    flex: 1;
    border: 0;
    border-radius: 50px 0 0 50px;
    outline: none;
    font-size: 14px;
    display: block;
    padding-left: 2px;
    padding-right: 20px; }
  .EditDocument .InputGroup label {
    flex: 1;
    padding: 5px 10px;
    margin-right: 20px; }
  .EditDocument .InputGroup i {
    display: flex;
    justify-content: center;
    align-items: center; }

.EditDocument .InputGroup.disabled {
  background-color: #d4d4d4;
  cursor: not-allowed; }
  .EditDocument .InputGroup.disabled input[disabled] {
    cursor: not-allowed;
    background-color: #d4d4d4; }

.EditDocument-Result p {
  margin-bottom: 10px;
  margin-left: 20px; }

.EditDocument-Result p:last-child {
  margin-bottom: 20px; }

.EditDocument .InputGroup-body {
  height: auto;
  border-radius: 0;
  padding: 0px; }

.EditDocument .input-file-container {
  position: relative;
  height: auto;
  margin-bottom: 0px; }

.EditDocument button[type="submit"] {
  margin: 0 auto;
  display: block;
  padding: 15px 50px;
  background-color: var(--dark);
  color: var(--blank);
  border: 0;
  border-radius: 25px;
  outline: none;
  cursor: pointer; }

.EditDocument-Log {
  font-family: 'Courier New', Courier, monospace;
  padding: 10px 10px;
  height: 500px;
  margin-top: 20px;
  border: 2px solid var(--dark);
  border-radius: 8px;
  overflow: auto; }
  .EditDocument-Log p {
    font-family: inherit;
    margin: 0; }
  .EditDocument-Log p.error {
    color: red; }
  .EditDocument-Log p.success {
    color: green; }

@media screen and (min-width: 720px) {
  .EditDocument .Section-content {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr; }
  .EditDocument-Log {
    height: 100%;
    border-radius: 0;
    margin-top: 0; } }

.DeleteDocument a {
  color: var(--dark); }

.DeleteDocument .InputGroup {
  height: 50px;
  display: flex;
  border-radius: 25px;
  padding: 10px 20px;
  border: 2px solid var(--dark);
  margin-bottom: 20px; }
  .DeleteDocument .InputGroup input {
    flex: 1;
    border: 0;
    border-radius: 50px 0 0 50px;
    outline: none;
    font-size: 14px;
    display: block;
    padding-left: 2px;
    padding-right: 20px; }
  .DeleteDocument .InputGroup label {
    flex: 1;
    padding: 5px 10px;
    margin-right: 20px; }
  .DeleteDocument .InputGroup i {
    display: flex;
    justify-content: center;
    align-items: center; }

.DeleteDocument .InputGroup.disabled {
  background-color: #d4d4d4;
  cursor: not-allowed; }
  .DeleteDocument .InputGroup.disabled input[disabled] {
    cursor: not-allowed;
    background-color: #d4d4d4; }

.DeleteDocument-Result p {
  margin-bottom: 10px;
  margin-left: 20px; }

.DeleteDocument-Result p:last-child {
  margin-bottom: 20px; }

.DeleteDocument .InputGroup-body {
  height: auto;
  border-radius: 0;
  padding: 0px; }

.DeleteDocument .input-file-container {
  position: relative;
  height: auto;
  margin-bottom: 0px; }

.DeleteDocument button[type="submit"] {
  margin: 0 auto;
  display: block;
  padding: 15px 50px;
  background-color: var(--dark);
  color: var(--blank);
  border: 0;
  border-radius: 25px;
  outline: none;
  cursor: pointer; }

.DeleteDocument-Log {
  font-family: 'Courier New', Courier, monospace;
  padding: 10px 10px;
  height: 500px;
  margin-top: 20px;
  border: 2px solid var(--dark);
  border-radius: 8px;
  overflow: auto; }
  .DeleteDocument-Log p {
    font-family: inherit;
    margin: 0; }
  .DeleteDocument-Log p.error {
    color: red; }
  .DeleteDocument-Log p.success {
    color: green; }

@media screen and (min-width: 720px) {
  .DeleteDocument .Section-content {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr; }
  .DeleteDocument-Log {
    height: 100%;
    border-radius: 0;
    margin-top: 0; } }
