.Section{
  display: none;
  &-title{
    margin-bottom: 20px;
    text-align: center;
    color: var(--dark);
  }
  &.is-active{
    display: block;
  }
}