.AddNews{
  a{
    color: var(--dark);
  }
  .InputGroup{
    height: 50px;
    display: flex;
    border-radius: 25px;
    padding: 10px 20px;
    border: 2px solid var(--dark);
    margin-bottom: 20px;
    input{
      flex: 1;
      border: 0;
      border-radius: 50px 0 0 50px;
      outline: none;
      font-size: 14px;
      display: block;
      padding-left: 2px;
      padding-right: 20px;
    }
    label{
      flex: 1;
      padding: 5px 10px;
      margin-right: 20px;
    }
    i{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .InputGroup-body{
    height: auto;
    border-radius: 0;
    padding: 0px;
  }
  .input-file-container {
    position: relative;
    height: auto;
    margin-bottom: 0px;
  }
  button[type="submit"]{
    margin: 0 auto;
    display: block;
    padding: 15px 50px;
    background-color: var(--dark);
    color: var(--blank);
    border: 0;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
  }
  &-Log{
    font-family: 'Courier New', Courier, monospace;
    padding: 10px 10px;
    height: 500px;
    margin-top: 20px;
    border: 2px solid var(--dark);
    border-radius: 8px;
    overflow: auto;
    p{
      font-family: inherit;
      margin: 0;
    }
    p.error{
      color: red;
    }
    p.success{
      color: green;
    }
  }
}
@media screen and (min-width: 720px){
  .AddNews{
    .Section-content{
      display: grid;
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr;
    }
    &-Log{
      height: 100%;
      border-radius: 0;
      margin-top: 0;
    }
  }
}

.EditNews{
  a{
    color: var(--dark);
  }
  .InputGroup{
    height: 50px;
    display: flex;
    border-radius: 25px;
    padding: 10px 20px;
    border: 2px solid var(--dark);
    margin-bottom: 20px;
    input{
      flex: 1;
      border: 0;
      border-radius: 50px 0 0 50px;
      outline: none;
      font-size: 14px;
      display: block;
      padding-left: 2px;
      padding-right: 20px;
    }
    label{
      flex: 1;
      padding: 5px 10px;
      margin-right: 20px;
    }
    i{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &-Result{
    p{
      margin-bottom: 10px;
      margin-left: 20px;
    }
    p:last-child{
      margin-bottom: 20px;
    }
  }
  .InputGroup-body{
    height: auto;
    border-radius: 0;
    padding: 0px;
  }
  .input-file-container {
    position: relative;
    height: auto;
    margin-bottom: 0px;
  }
  button[type="submit"]{
    margin: 0 auto;
    display: block;
    padding: 15px 50px;
    background-color: var(--dark);
    color: var(--blank);
    border: 0;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
  }
  &-Log{
    font-family: 'Courier New', Courier, monospace;
    padding: 10px 10px;
    height: 500px;
    margin-top: 20px;
    border: 2px solid var(--dark);
    border-radius: 8px;
    overflow: auto;
    p{
      font-family: inherit;
      margin: 0;
    }
    p.error{
      color: red;
    }
    p.success{
      color: green;
    }
  }
}
@media screen and (min-width: 720px){
  .EditNews{
    .Section-content{
      display: grid;
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr;
    }
    &-Log{
      height: 100%;
      border-radius: 0;
      margin-top: 0;
    }
  }
}

.DeleteNews{
  a{
    color: var(--dark);
  }
  .InputGroup{
    height: 50px;
    display: flex;
    border-radius: 25px;
    padding: 10px 20px;
    border: 2px solid var(--dark);
    margin-bottom: 20px;
    input{
      flex: 1;
      border: 0;
      border-radius: 50px 0 0 50px;
      outline: none;
      font-size: 14px;
      display: block;
      padding-left: 2px;
      padding-right: 20px;
    }
    label{
      flex: 1;
      padding: 5px 10px;
      margin-right: 20px;
    }
    i{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &-Result{
    p{
      margin-bottom: 10px;
      margin-left: 20px;
    }
    p:last-child{
      margin-bottom: 20px;
    }
  }
  .InputGroup-body{
    height: auto;
    border-radius: 0;
    padding: 0px;
  }
  .input-file-container {
    position: relative;
    height: auto;
    margin-bottom: 0px;
  }
  button[type="submit"]{
    margin: 0 auto;
    display: block;
    padding: 15px 50px;
    background-color: var(--dark);
    color: var(--blank);
    border: 0;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
  }
  &-Log{
    font-family: 'Courier New', Courier, monospace;
    padding: 10px 10px;
    height: 500px;
    margin-top: 20px;
    border: 2px solid var(--dark);
    border-radius: 8px;
    overflow: auto;
    p{
      font-family: inherit;
      margin: 0;
    }
    p.error{
      color: red;
    }
    p.success{
      color: green;
    }
  }
}
@media screen and (min-width: 720px){
  .DeleteNews{
    .Section-content{
      display: grid;
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr;
    }
    &-Log{
      height: 100%;
      border-radius: 0;
      margin-top: 0;
    }
  }
}

//ESTILOS PARA EL INPUT FILE
.input-file-container {
  /* position: relative;
  height: 100px; */
}
.js .input-file-trigger {
  display: block;
  padding: 5px 45px;
  background: var(--dark);
  color: #fff;
  font-size: 1em;
  transition: all .4s;
  cursor: pointer;
}
.js .input-file {
  position: absolute;
  top: 0; left: 0;
  width: 225px;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;
}
.js .input-file:hover + .input-file-trigger,
.js .input-file:focus + .input-file-trigger,
.js .input-file-trigger:hover,
.js .input-file-trigger:focus {
  background: #34495E;
  color: #39D2B4;
}

.file-return {
  padding: 10px 20px;
  margin: 0;
}
.file-return:not(:empty) {
}
.js .file-return {
  font-style: italic;
  font-size: .9em;
  font-weight: bold;
}
.js .file-return:not(:empty):before {
  content: "Selected file: ";
  font-style: normal;
  font-weight: normal;
}
