.Panel{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  transform: translate(-100%);
  transition: transform var(--time-transition);
  text-align: left;
  background-color: var(--dark);
  &-btn{
    width: 100%;
    padding: 10px;
    margin-right: 15px;
    border: 1px solid var(--blank);
    outline: none;
    background-color: transparent;
    color: var(--blank);
    border-radius: 2px;
    flex: 0;
    z-index: 10;
  }
  &.is-active{
    transform: translate(-100%);
    transition: transform var(--time-transition);
  }
}