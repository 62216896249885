canvas{
  margin: 0 auto;
}
@media screen and (min-width: 720px){
  .Tables{
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px;
    canvas{
      max-width: 400px;
      margin: 0 auto;
    }
    .Total{
      margin-top: 0;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr;
      &>p{
        margin: 0;
        border-radius: 2px;
      }
    }
  }
}
@media screen and (min-width: 770px){
  .Tables{
    canvas{
      max-width: 400px;
      margin: 0 auto;
    }
  }
}
@media screen and (min-width: 1024px){
  .Tables{
    align-content: center;
    align-items: center;
    canvas{
      max-width: 500px;
    }
    .Total{
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(1, 1fr);
      align-content: start;
      align-items: start;
      &>p{
        margin: 0;
        border-radius: 5px;
        height: auto;
        padding: 30px;
      }
    }
  }
}